<template>
    <div class="right-sidevar-wrap order-bar-leftside">
        <site-logo></site-logo>
        <div class="menu-listing">
            <side-button></side-button>
            
            <div class="weight-scales weight-scales-one">
                <h2>כמות ביח'</h2>
                <input type="text" class="wieght-scale-btn" value="1" readonly />
            </div>
            <div class="weight-scales">
                <h2>טרה בק"ג</h2>
                <input type="text" class="wieght-scale-btn" value="0.000" readonly />
            </div>
            <div class="weight-scales">
                <h2>מחיר ליחידה</h2>
                <input type="text" class="wieght-scale-btn" value="6.00" readonly />
            </div>
            <div class="weight-scales weight-scales-last">
                <h2>סה"כ לתשלום</h2>
                <input type="text" class="wieght-scale-btn" value="6.00" readonly />
            </div>
        </div>
    </div>
</template>

<script>
import SiteLogo from './sitelogo';
import SideButton from './sidebutton';
export default {
    components:{ SiteLogo,SideButton },
    mounted() {
    },
    methods: {
    }
};
</script>
<script>

</script>