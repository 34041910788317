<template>
    <div style="
    position: absolute;
    width: 15%;
    z-index: 1000;
    top: 3%;
    right: 2%;
">
        <div v-if="alert.message" :class="`alert ${alert.type}`" @click="closepopup"><button class=close>×</button>
            {{alert.message}}
        </div>
    </div>
</template>

<script>
import { mapState,mapActions } from 'vuex'
export default {
    computed: {
        ...mapState({ 
            alert: state => state.alert,
        }),
        ...mapActions('alert', ['clear']),
    },
    methods: {
        closepopup(){
            this.$store.dispatch('alert/clear');
        }
    }
};
</script>