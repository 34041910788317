<template>
      <div class="site-logo">
          <a href="#" title="Sellio Logo"><img src="img/logo.png" alt="Sellio" class="img-fluid"></a>
          <div class="time-n-date"><span class="today-date" id="today-date">11/08/2021</span><span class="today-time" id="today-time">20:00</span></div>
      </div>
</template>

<script>
export default {
    mounted() {
        this.startTime()
    },
    methods: {
        startTime() {
          const today = new Date();
          let d = today.getDate();
          let mm = today.getMonth()+1;
          let y = today.getFullYear();
          let h = today.getHours();
          let m = today.getMinutes();
          let s = today.getSeconds();
          m = this.checkTime(m);
          s = this.checkTime(s);
          document.getElementById('today-date').innerHTML =  d + "/" + mm + "/" + y;
          document.getElementById('today-time').innerHTML =  h + ":" + m + ":" + s;
          setTimeout(this.startTime, 1000);
        },
        checkTime(i) {
          if (i < 10) {i = "0" + i}  // add zero in front of numbers < 10
          return i;
        }
    }
};
</script>
