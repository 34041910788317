<template>
    <alert-modal></alert-modal>
    <div class="page-content" :class="getBodyClass">
        <div class="body-content-wrap">
            <router-view></router-view>
            <left-side-bar></left-side-bar>
        </div>
    </div> 
</template>

<script>
import LeftSideBar from './includes/LeftSideBar'
import AlertModal from '../components/alertmodal' 
import { mapActions } from 'vuex'

export default {
    components:{LeftSideBar,AlertModal},
    computed: {
        getBodyClass: {
            get: function () {
                if(this.$route.path == '/orders-list'){ return 'order-listing-page'; }
                if(this.$route.path == '/order'){ return 'order-edit-page'; }
                return '';
            },
            set: function () { }
        },
        ...mapActions('user', ['getUserData']),
    },
    mounted () { 
        this.$store.dispatch('user/getUserData');
    },
    methods: {
    }
};
</script>