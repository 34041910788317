<template>
    <div class="home-btn">
        <button type="button" class="btn-site"><img src="img/home-green.svg" hover-img="img/home-black.svg" class="img-fluid" alt="Home" /></button>
    </div>
    <div class="btns-grid">
        <button type="button" class="order-page-btn"><a href="https://index.sellio.co.il/pos/order-page.php"><span class="d-block">{{orderSearch.orders.length}}</span> הזמנות להכנה</a></button>
        <button type="button"  class="order-list-btn"><a href="https://index.sellio.co.il/pos/order-listing.php"><span class="d-block">{{orderSearch.collectedorders.length}}</span> הזמנות למסירה</a></button>
        <button type="button"><i class="fa fa-angle-right" aria-hidden="true"></i>מלאי מוצרים</button>
        <button type="button">ניהול מוצרים</button>
        <button type="button"><i class="fa fa-angle-right" aria-hidden="true"></i>קופה</button>
        <button type="button">עוד</button>
        <router-link style="color:white" to="/orders-list">Go to list</router-link>
        <router-link style="color:white" to="/order">Go to order</router-link>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState({ 
            orderSearch: state => state.ordersearch,
        }),
    }, 
};
</script>